@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: "Inter", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Montserrat", sans-serif;

}

